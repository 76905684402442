<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Scams/List</h4>
          <div>
            <router-link :to="can('post-create') ? {name:'appScamAlertCreate'} : '#'">
              <span :title="can('post-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" :options="paginationList" :close-on-select="true"
                                label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Scam Category</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedScamCategory" class="" :options="scamCategoryList"
                                :close-on-select="true"
                                placeholder="Select Scam Category Name" label="name" track-by="name"
                                :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Is Featured</label>
                <VueMultiselect v-model="selectedIsFeatured" class="" :options="featuredList" :close-on-select="true"
                                label="name"
                                track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Status</label>
                <VueMultiselect v-model="selectedStatus" class="" :options="statusList" :close-on-select="true"
                                placeholder="Select status" label="name" track-by="value" :show-labels="false"
                                :allow-empty="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9">
                <input v-model="getScamsParams.title" class="form-control search-admin-input-element" type="text"
                       placeholder="Search By Title">
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyScamFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" style="width: 5%">SL</th>
                    <th class="position-relative" style="width: 10%">Image</th>
                    <th class="position-relative" style="width: 30%">Title</th>
                    <th class="position-relative" style="width: 30%">Scam Category</th>
                    <th class="position-relative" style="width: 5%">Is Featured</th>
                    <th class="position-relative" style="width: 5%">STATUS</th>
                    <th class="position-relative text-right" style="width: 15%">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(scam, index) in scams" :key="index">
                    <td>
                      <span>{{ index + 1 }}</span>
                    </td>
                    <td>
                      <img :src="scam.thumbnail" class="img-fluid" alt="scam image" style="height: 3rem;">
                    </td>
                    <td>
                      <span>{{ scam.title }}</span>
                    </td>
                    <td>{{ scam.postCategory?.name ?? '' }}</td>
                    <td>
                      <button class="btn"
                              :disabled="!can('post-update')"
                              :title="can('post-update') ? `${scam.is_featured} | Click to update` : `${scam.is_featured} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#scamIsFeaturedUpdateAlertModal"
                              @click="modelForUpdating = {modelId: scam.id, existingData: {is_featured: scam.is_featured == 'Yes' ? 1 : 0}}">
                        <span class="badge"
                              :class="scam.is_featured == 'Yes' ? 'badge-light-success' : 'badge-light-danger'">{{
                            scam.is_featured
                          }}</span>
                      </button>
                    </td>
                    <td>
                      <button class="btn"
                              :disabled="!can('post-update')"
                              :title="can('post-update') ? `${scam.status} | Click to update` : `${scam.status} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#scamStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: scam.id, existingData: {status: scam.status}}">
                        <span class="badge"
                              :class="scam.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">{{
                            scam.status
                          }}</span>
                      </button>
                    </td>

                    <td>
                      <div class="d-flex align-items-top justify-content-around">
                        <div>
                          <router-link
                              :to="can('post-view') ? {name: 'appScamAlertView',params: { id: scam.id }} : '#'"
                              :title="can('post-view') ? 'View' : 'View Forbidden'"
                              class="cursor-pointer view-edit-delete-icon"
                              :style="{ height: '18px' }">
                            <i class="bx bx-show"></i>
                          </router-link>
                        </div>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown"
                                                          aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                          <div class="dropdown-menu" style="min-height: 100px">
                            <router-link :to="{name:'appScamAlertEdit', params:{id:scam.id}}"
                                         :disabled="!can('post-update')"
                                         :title="can('post-update') ? 'Update' : 'Update Forbidden'"
                                         class="dropdown-item">Update
                            </router-link>
                            <button :disabled="!can('post-delete')" @click="modelIdForDelete = scam.id"
                                    data-toggle="modal"
                                    :title="can('post-delete') ? 'Delete' : 'Delete Forbidden'"
                                    data-target="#deleteBlogModal" class="dropdown-item">Delete
                            </button>

                          </div>
                        </div>
                      </div>
                    </td>

                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyScamFilter"/>
              </div>
            </div>
          </div>
        </div>

        <scam-status-update-alert-modal :model-for-updating="modelForUpdating" model-name="scam"
                                        modal-name="scamStatusUpdateAlertModal"
                                        @confirmModelUpdating="scamStatusUpdating"/>

        <BlogIsFeaturedUpdateAlertModal :model-for-updating="modelForUpdating" model-name="scam"
                                        modal-name="scamIsFeaturedUpdateAlertModal"
                                        @confirmModelUpdating="scamIsFeaturedUpdating"/>


        <BlogDeleteAlertModal modal-name="deleteBlogModal" model-name="scam" :model-id="modelIdForDelete"
                              @confirmModelDeletion="onConfirmModelDeletion"/>

      </section>
    </template>
  </AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout';
import ListPagination from '@/components/backEnd/pagination/ListPagination';
import ScamStatusUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
import BlogIsFeaturedUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';
import BlogDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";

export default {
  name: "ScamAlertList.vue",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    ScamStatusUpdateAlertModal,
    BlogIsFeaturedUpdateAlertModal,
    BlogDeleteAlertModal,
    ListPagination,
    VueMultiselect
  },
  data() {
    return {
      scam: {},
      unauthorized: 'You are unauthorized to perform this action.',
      viewBlogObj: {},
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedIsFeatured: {
        value: '',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      selectedScamCategory: {
        value: '',
        name: 'Any'
      },
      getScamsParams: {
        post_category_id: '',
        title: '',
        is_featured: '',
        status: '',
        paginate: 1,
        order_by_id: 'DESC',
        pagination: '',
        page: '',
        with_relation: ['postCategory', 'tags', 'media'],
      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination'],
      },

      getScamCategoriesParams: {
        status: 1,
        order_by_name: 'ASC',
      },
      modelForUpdating: {
        ModelId: '',
        existingData: {},
      },

      modelIdForDelete: '',
    };
  },

  watch: {
    selectedPagination(selectedPagination) {
      this.getScamsParams.pagination = selectedPagination.value;
    },
    selectedScamCategory(selectedScamCategory) {
      this.getScamsParams.post_category_id = selectedScamCategory.value;
    },
    selectedIsFeatured(selectedIsFeatured) {
      this.getScamsParams.is_featured = selectedIsFeatured.value;
    },
    selectedStatus(selectedStatus) {
      this.getScamsParams.status = selectedStatus.value;
    },
  },

  computed: {
    ...mapGetters({
      scams: 'appScams/scams',
      paginateLinks: 'appScams/paginateLinks',
    }),

    paginationList() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },

    scamCategoryList() {
      let scamCategories = this.$store.getters['appScamCategories/scamCategories'].map((scamCategory) => {
        let id = scamCategory.id;
        let name = scamCategory.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'Any'}, ...scamCategories];
    },

    statusList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },

    featuredList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'No'
        },
        {
          value: 1,
          name: 'Yes'
        },
      ]
    },
  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getScamCategories: 'appScamCategories/getScamCategories',
      getScams: 'appScams/getScams',
      resetScams: 'appScams/resetScams',
      putScamOnList: 'appScams/putScamOnList',
      deleteScamOnList: 'appScams/deleteScamOnList',
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getScamCategoryList() {
      await this.getScamCategories(this.getScamCategoriesParams);
    },

    async getScamList() {
      await this.getScams(this.getScamsParams).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      })
    },

    async applyScamFilter(pageNumber) {
      this.loader(true);
      this.getScamsParams.page = pageNumber;
      await this.getScamList();
      this.loader(false);
    },

    async scamStatusUpdating(scamStatusUpdating) {
      console.warn(scamStatusUpdating, "scamStatusUpdating")
      await this.scamStatusUpdatingOnList(scamStatusUpdating);
    },

    async scamIsFeaturedUpdating(scamIsFeaturedUpdating) {
      await this.scamIsFeaturedUpdatingOnList(scamIsFeaturedUpdating);
    },

    async scamStatusUpdatingOnList(scamStatusUpdating) {
      let dataObj = {
        id: scamStatusUpdating.modelForUpdating.modelId,
        data: {
          status: scamStatusUpdating.modelForUpdating.existingData.status === "Active" ? 0 : 1,
        }
      }

      this.putScamOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Scam status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async scamIsFeaturedUpdatingOnList(scamIsFeaturedUpdating) {
      let dataObj = {
        id: scamIsFeaturedUpdating.modelForUpdating.modelId,
        data: {
          is_featured: scamIsFeaturedUpdating.modelForUpdating.existingData.is_featured,
        }
      }

      this.putScamOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Scam is featured updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async deleteSingleScamOnList(id) {
      this.deleteScamOnList(id).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Scam Post Deleted",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      })
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleScamOnList(confirmModelDeletion.modelId);
    },

  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getScamCategoryList();
    await this.getScamList();
    this.loader(false);
  },

  async created() {
    await this.resetScams();
  },

}
</script>


<style scoped>

.view-edit-delete-icon {
  color: #B3C0CE;
  transition: all 0.2s;
}

.view-edit-delete-icon:hover {
  color: #5A8DEE;
}

.search-admin-input-element {
  min-height: 41px !important;
  padding: 8px 40px 8px 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
</style>